import React from 'react'
import { Link } from 'react-router-dom'
import LogoTransparent from '../Pictures/logo_low.png'
import bannerPic1 from '../Pictures/banner1.jpg'
import { Events } from '../Data/Events';
import { News } from '../Data/News';
import { Fade, Zoom } from 'react-awesome-reveal'
import { Container, Divider,Icon,Header, Image,ImageGroup } from 'semantic-ui-react';
import {Row,Col,Card, Carousel} from 'antd'
import Meta from 'antd/es/card/Meta';
import Humanitas from '../Pictures/Partners/Humanitas.jpg'
import { Partners } from '../Data/Partners';
import { useMediaQuery } from 'react-responsive';




const Home = () => {
  let bannerStyle ={
    backgroundImage: `url(${bannerPic1})`,
  }

  const mobile = useMediaQuery({query:'(max-width:1130px)'})


  
  const sortedEvents = [...Events].sort((a,b)=>a.sort-b.sort).filter(e=>e.sort>=new Date())
  sortedEvents.length = 2

  const events = sortedEvents.map((Event)=>  
  (

    <>
    <Zoom triggerOnce={true} style={{textAlign:'center'}}>
      <Link to={`/eventdetails/${Event.id}`} reloadDocument key={Event.id} >
    <div id='events'>
    {Event.image?<img alt='thumbnail' src={Event.image} style={{width:'10.417vw',height:'10.417vw'}} loading='lazy' className='eventImage'/>:<></>}
    <h1>{Event.event}</h1>
    <h4 style={{color:Event.location==='TBA'?'gray':'black'}}>{Event.location?<Icon name='map'/>:<></>}{Event.location}</h4>
    <h4 style={{color:Event.date==='TBA'?'gray':'black'}}><Icon name='calendar'/>{Event.date}</h4>
    <h4 style={{color:Event.time==='TBA'?'gray':'black'}}><Icon name='time'/>{Event.time}</h4>
    
    </div>
    <br />
    <br/>


    </Link>
</Zoom>
    </>
  )
 )

 const news = News.map((News)=>
  <>
     <Zoom triggerOnce style={{textAlign:'center'}}>
  <Link to={`/newsdetails/${News.id}`} reloadDocument key={News.id}>
  <div id='news'>
  <img  alt='thumbnail' src={News.image} style={{width:'10.417vw'}} loading='lazy' />
  <h2>{News.headline}</h2>
  <p style={{textAlign:'center'}}>{News.short}</p>
  </div>
  <br />
  <br />
  </Link>
  </Zoom>

</>
 )

  return (
    <div id='home'>
      <div id='banner'style={bannerStyle}>
        <div id='bannerText'>
        <Zoom>
        <div id="summary">
        <Link reloadDocument to={'/'}><img alt="logo" src={LogoTransparent} className='bannerPic'/></Link>
        <Header style={{fontWeight:'bold',fontSize:'24px', color:'white'}}>GHANA UNION CZECH REPUBLIC</Header>
      </div>
          <br/>
          <Link id='joinUs' to={'/joinus'}>Join Us</Link>
          </Zoom>      
        </div>
      </div>


      
      
      
    <div id='shortcut' >


    <Zoom triggerOnce={true}>
   <Container className='homeContent' id='join'>

        <Divider horizontal><h1>Who Are We?</h1></Divider>
        <p style={{textAlign:'left'}}>The Ghana Union Czech Republic is a registered, welfare, non-profit, and non-governmental association.</p>
        <br/>
        <br/>
      <Link to={'/about'} reloadDocument id='JoinUs'>About Us</Link>
      </Container>
</Zoom>


  
    <div id='news'>

    <Container textAlign='center'  id='join'>
<Zoom triggerOnce >

<Divider horizontal ><h1>What We Do</h1></Divider>
<li>Support for members in case of marriage, childbirth, bereavement, hospitalization, etc. </li>
          <li>We witness/endorse the Ghana passport application form for all Ghanaians when acquiring a new or renewing an existing passport </li>
          <li>We organize educational, integration and healthcare programs relevant to our stay in the Czech Republic</li>
          <br/>
          <Link to={'/joinus'} reloadDocument id='joinUs'>Learn More</Link>
          </Zoom>

</Container>

          
    {events.every((e)=>{return e ===''})?<><h1>No upcoming events</h1>      
    <Link to={'/events'} reloadDocument id='joinUs'>View All</Link>
</>:
    <>
      <Container style={{backGroundColor:'white', paddingRight:'2.604vw', paddingLeft:'2.604vw'}} className='homeContent'>
        <Divider horizontal ><h1>Upcoming Events</h1></Divider>
          {events}
      </Container>
      <Link to={'/events'} reloadDocument id='joinUs'>View All</Link>
    
      </>
      }

<br/><br/><br/><br/>

    <Divider horizontal><h1>News</h1></Divider>

        {news.every((e)=>{return e ===''})
        ?<>
        <h1>No news to show</h1>
        <Link to={'/News'} reloadDocument id='joinUs'>View All</Link>
        </>:
        <>
      <Container className='homeContent' textAlign='center' >
          {news}
        </Container>
      <Link to={'/news'} reloadDocument id='joinUs'>View All</Link>
</>  
        }




</div>

    </div>
<Fade>
    <div id='partners'>
      <Divider horizontal><h1>Our Partners</h1></Divider>

      <Carousel autoplay arrows={true} fade dots={mobile?false:true}>
        {Partners.map((p)=>(
          <div className='carousel' key={p.id}>
            <Image centered rounded src={p.thumb}  />
            <h2>{p.name}</h2>
            <p>{p.description}</p>
            <br/><br/>
            <Link className='button-1' to={p.web} target='_blank' id='joinUs'>Learn More</Link>
          </div>
        ))}
     </Carousel>
      
    </div>

</Fade>
    </div>
  )
}



export default Home
