import grill1 from '../Pictures/Gallery/Grill 2023/Grill2023(1).jpg'
import grill2 from '../Pictures/Gallery/Grill 2023/Grill2023(2).jpg'
import grill3 from '../Pictures/Gallery/Grill 2023/Grill2023(3).jpg'
import grill4 from '../Pictures/Gallery/Grill 2023/Grill2023(4).jpg'
import grill5 from '../Pictures/Gallery/Grill 2023/Grill2023(5).jpg'

import grillBrno1 from '../Pictures/Gallery/Grill Brno 2023/grillbrno2023(1).jpg'
import grillBrno2 from '../Pictures/Gallery/Grill Brno 2023/grillbrno2023(2).jpg'


import gt1 from '../Pictures/Gallery/GetTogether 2023/GetTogether2023(1).jpg'
import gt2 from '../Pictures/Gallery/GetTogether 2023/GetTogether2023(2).jpg'
import gt5  from '../Pictures/Gallery/GetTogether 2023/GetTogether2023(3).jpg'
import gt3 from '../Pictures/Gallery/GetTogether 2023/2GetTogether2023(1).jpg'
import gt4 from '../Pictures/Gallery/GetTogether 2023/2GetTogether2023(2).jpg'

import wf1 from '../Pictures/Gallery/Welfare/Welfare1.jpg'
import wf2 from '../Pictures/Gallery/Welfare/Welfare2.jpg'
import wf3 from '../Pictures/Gallery/Welfare/Welfare3.jpg'
import wf4 from '../Pictures/Gallery/Welfare/Welfare4.jpg'
import wf5 from '../Pictures/Gallery/Welfare/Welfare5.jpg'

export const gallery = [
    /*Template:
    {
        id: 1,
        original: SportsEvent2023,
        thumbnail: SportsEvent2023,
        description: 'Sports Event on March 11 2023',
        category: 'Games',
        eventId: 1
    },

    Ids:
    Parties: 1,
    Get togethers: 2,
    Welfare: 3,
    Meetings: 4,
    Games: 5,
    */

    {
        id: 1,
        original: grill1,
        thumbnail: grill1,
        description: 'Summer Grill Party 2023',
        category: 'Parties',
        eventId: 1

    },
    {
        id: 1,
        original: grill2,
        thumbnail: grill2,
        description: 'Summer Grill Party 2023',
        category: 'Parties',
        eventId: 1

    },
    {
        id: 1,
        original: grill3,
        thumbnail: grill3,
        description: 'Summer Grill Party 2023',
        category: 'Parties',
        eventId: 1

    },
    {
        id: 1,
        original: grill4,
        thumbnail: grill4,
        description: 'Summer Grill Party 2023',
        category: 'Parties',
        eventId: 1

    },
    {
        id: 1,
        original: grill5,
        thumbnail: grill5,
        description: 'Summer Grill Party 2023',
        category: 'Parties',
        eventId: 1

    },
    {
        id: 1,
        original: grillBrno1,
        thumbnail: grillBrno1,
        description: 'Summer Grill Party in Brno 2023',
        category: 'Parties',
        eventId: 1

    },
    {
        id: 1,
        original: grillBrno2,
        thumbnail: grillBrno2,
        description: 'Summer Grill Party in Brno 2023',
        category: 'Parties',
        eventId: 1

    },
    {
        id: 2,
        original: gt1,
        thumbnail: gt1,
        description: 'Get together on September 23 2023',
        category: 'Get togethers',
        eventId: 2
    },
    {
        id: 2,
        original: gt2,
        thumbnail: gt2,
        description: 'Get together on September 23 2023',
        category: 'Get togethers',
        eventId: 2
    },
    {
        id: 2,
        original: gt5,
        thumbnail: gt5,
        description: 'Get together on September 23 2023',
        category: 'Get togethers',
        eventId: 2
    },
    {
        id: 3,
        original: gt3,
        thumbnail: gt3,
        description: 'Ghana Union gift to a new born child',
        category: 'Welfare',
        eventId: 2
    },
    {
        id: 3,
        original: gt4,
        thumbnail: gt4,
        description: 'Ghana Union gift to a new born child',
        category: 'Welfare',
        eventId: 2
    },


    {
        id: 3,
        original: wf1,
        thumbnail: wf1,
        description: '',
        category: 'Welfare',
        eventId: 2
    },    {
        id: 3,
        original: wf2,
        thumbnail: wf2,
        description: '',
        category: 'Welfare',
        eventId: 2
    },    {
        id: 3,
        original: wf3,
        thumbnail: wf3,
        description: '',
        category: 'Welfare',
        eventId: 2
    },    {
        id: 3,
        original: wf4,
        thumbnail: wf4,
        description: '',
        category: 'Welfare',
        eventId: 2
    },
    {
        id: 3,
        original: wf5,
        thumbnail: wf5,
        description: '',
        category: 'Welfare',
        eventId: 2
    },







]